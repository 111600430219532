import { gql } from "@apollo/client";

const GET_MESSAGE_FROM = gql`
  query getMesssage($id: ID!) {
    page(id: $id, idType: ID) {
      messageFrom {
        title
        message
        featuredImage {
          sourceUrl
          srcSet
        }
        name
        designation
      }
    }
  }
`;

export default GET_MESSAGE_FROM;
