import React from "react";
import { Col } from "react-bootstrap";
import Moment from "react-moment";
import { pdf } from "../../assets";

const ReportCard = ({ data }) => {
  return (
    <Col md={3} className="mb-4">
      <a
        href={data.reportFile.mediaItemUrl}
        target="_blank"
        rel="noreferrer"
        className="download-card"
        download
      >
        <div className="download-card-header">
          <div className="pdf-logo">
            <img src={pdf} alt="pdf logo" />
          </div>
          <span className="download-button">Click to download</span>
        </div>
        <div className="download-card-body">
          <Moment format="DD MMM YYYY" className="date">
            {data.dateCustom}
          </Moment>
          <span className="title">{data.title}</span>
        </div>
      </a>
    </Col>
  );
};

export default ReportCard;
