import { gql } from "@apollo/client";

const GET_MISSION_VISION_GOAL = gql`
  query getMissionVisionGoal($id: ID!) {
    page(id: $id, idType: ID) {
      missionVisionGoal {
        variousInfo {
          title
          content
          featuredImage {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_MISSION_VISION_GOAL;
