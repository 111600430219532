import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import OtherProducts from "../components/product/OtherProducts";
import ProductList from "../components/product/ProductList";
import GET_A_PRODUCT from "../queries/product/getAProduct";
import Query from "../utils/Query";

const Product = () => {
  const slug = useParams().slug;

  return (
    <Query query={GET_A_PRODUCT} slug={slug}>
      {({ data: { product } }) => {
        console.log(product);
        return product ? (
          <div className="product">
            <div className="product-header">
              <Container>
                <Row>
                  <Col md={7} className="left">
                    <div className="left-header">
                      <span className="pill">समृद्धिको सहयात्री</span>
                      <h1>{product.product.title}</h1>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: product.product.description,
                      }}
                    />
                    {product.product.hasSubType && (
                      <a id="types" href="#types">
                        Explore our various {product.product.title} services
                        &nbsp; &rarr;
                      </a>
                    )}
                  </Col>
                  <Col md={5} className="right">
                    <img
                      src={product.product.featuredimage.sourceUrl}
                      srcSet={product.product.featuredimage.srcSet}
                      alt=""
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            {product.product.hasSubType && (
              <Container className="product-body">
                <Row>
                  <Col md={9}>
                    <ProductList
                      title={product.product.title}
                      data={product.product.productSubtype}
                    />
                  </Col>
                  <Col md={3}>
                    <OtherProducts slug={slug} />
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        ) : (
          <Container>
            <p className="py-5">Error 404: Service not found</p>
          </Container>
        );
      }}
    </Query>
  );
};

export default Product;
