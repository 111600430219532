import React from "react";
import { Container } from "react-bootstrap";
import BranchDetail from "../components/branches/BranchDetail";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import GET_BRANCHES from "../queries/branches/getBranches";
import Query from "../utils/Query";

const Branches = () => {
  return (
    <Query query={GET_BRANCHES}>
      {({
        data: {
          branches: { nodes },
        },
      }) => {
        return (
          <div>
            <BreadcumbHeader title="Branches" heading="Branches" />
            <Container className="branches">
              {nodes.map(({ branchNetwork }, key) => (
                <BranchDetail key={key} data={branchNetwork} />
              ))}
            </Container>
          </div>
        );
      }}
    </Query>
  );
};

export default Branches;
