import React from "react";
import ProductItem from "./ProductItem";

const ProductList = ({ title, data }) => {
  return (
    <div className="product-children">
      <h2>Our various {title} services</h2>
      <ul className="product-children-list">
        {data.map((entry, key) => (
          <ProductItem data={entry} key={key} index={key} />
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
