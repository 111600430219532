import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import MobileNavbar from "./mobileNav";
import LargeNavbar from "./large";
import { useQuery } from "@apollo/client";
import GET_REPORT_TYPES from "../../../../queries/report/getReportTypes";
import GET_ALL_PRODUCT from "../../../../queries/product/getAllProducts";
import GET_ALL_TEAMS from "../../../../queries/teams/getAllTeams";

const aboutUsData = [
  {
    value: "About",
    link: "/about",
  },
  {
    value: "Board of Directors",
    link: "/board-of-directors",
  },
];

const informationData = [
  {
    value: "News & Events",
    link: "/news",
  },
  {
    value: "Notices",
    link: "/notice",
  },
  {
    value: "Download",
    link: "/downloads",
  },
];

const NavBar = () => {
  const [reportData, setReportData] = useState([]);
  const [productData, setProductData] = useState([]);
  const {
    data: reportTypes,
    loading: reportLoading,
    error: reportError,
  } = useQuery(GET_REPORT_TYPES);

  const {
    data: productTypes,
    loading: productLoading,
    error: productError,
  } = useQuery(GET_ALL_PRODUCT);

  const {
    data: teamTypes,
    loading: teamLoading,
    error: teamError,
  } = useQuery(GET_ALL_TEAMS);

  useEffect(() => {
    if (!productLoading && !productError && productData.length === 0) {
      const temp = productTypes.products.nodes.map((product) => ({
        value: product.product.title,
        link: `/product/${product.slug}`,
      }));
      setProductData(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTypes]);

  useEffect(() => {
    if (!reportLoading && !reportError && reportData.length === 0) {
      const temp = reportTypes.reports.nodes.map((report) => ({
        value: report.reports.title,
        link: `/report/${report.slug}`,
      }));
      setReportData(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTypes]);

  useEffect(() => {
    if (!teamLoading && !teamError && aboutUsData.length <= 2) {
      const temp = teamTypes.teams.nodes.map((team) => ({
        value: team.subTeam.title,
        link: `/team/${team.slug}`,
      }));
      aboutUsData.push(...temp);
      aboutUsData.push({
        value: "Management Team",
        link: "/management-team",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamTypes]);

  return (
    <nav className="my-navbar">
      <Container>
        {/* For screen greater than 576px */}
        <LargeNavbar
          aboutUsData={aboutUsData}
          informationData={informationData}
          productData={productData}
          reportData={reportData}
        />
        {/* For screen less than 576px */}
        <MobileNavbar
          aboutUsData={aboutUsData}
          informationData={informationData}
          productData={productData}
          reportData={reportData}
        />
      </Container>
    </nav>
  );
};

export default NavBar;
