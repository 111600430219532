import React from "react";
import { Container, Row } from "react-bootstrap";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import ManagementCard from "../components/management/ManagementCard";
import GET_MANAGEMENT_MEMBERS from "../queries/management/getAllManagementMembers";
import Query from "../utils/Query";

const Management = () => {
  return (
    <div>
      <BreadcumbHeader
        title="Management Team"
        heading="Our Management Team Members"
      />

      <Container className="members">
        <div className="mb-4">
          <Row>
            <Query query={GET_MANAGEMENT_MEMBERS}>
              {({
                data: {
                  managementTeams: { nodes },
                },
              }) => {
                return nodes.map(({ managementTeam }, key) => (
                  <ManagementCard data={managementTeam} key={key} />
                ));
              }}
            </Query>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Management;
