import { gql } from "@apollo/client";

const GET_ALL_NOTICE = gql`
  query getAllNotice($sort: OrderEnum!) {
    notices(first: 1000, where: { orderby: { field: DATE, order: $sort } }) {
      nodes {
        id
        date
        notices {
          title
          featuredImage {
            sourceUrl
            srcSet
          }
          notice {
            mediaItemUrl
            mediaType
          }
        }
      }
    }
  }
`;

export default GET_ALL_NOTICE;
