import { gql } from "@apollo/client";

const GET_POPUP = gql`
  query getPopups {
    popupInfos(
      first: 20
      where: { status: PUBLISH, orderby: { field: DATE, order: DESC } }
    ) {
      nodes {
        title
        popupInfo {
          image {
            mediaItemUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_POPUP;
