import React from "react";
import { Col } from "react-bootstrap";
import Moment from "react-moment";
import { pdf } from "../../assets";

const DownloadCard = ({ data }) => {
  return (
    <Col md={3} className="mb-4">
      <a
        href={data.download.file.mediaItemUrl}
        download
        target="_blank"
        rel="noreferrer"
        className="download-card"
      >
        <div className="download-card-header">
          <div className="pdf-logo">
            <img src={pdf} alt="pdf logo" />
          </div>
          <span className="download-button">Click to download</span>
        </div>
        <div className="download-card-body">
          <Moment format="DD MMM YYYY" className="date">
            {data.date}
          </Moment>
          <span className="title">{data.download.title}</span>
        </div>
      </a>
    </Col>
  );
};

export default DownloadCard;
