import { gql } from "@apollo/client";

const GET_SLIDER = gql`
  query getCarousel {
    page(id: "cG9zdDoyNA==", idType: ID) {
      carousel {
        carousel {
          sourceUrl
          srcSet
        }
      }
    }
  }
`;

export default GET_SLIDER;
