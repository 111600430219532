import { gql } from "@apollo/client";

const GET_FEATURED_NEWS = gql`
  query getFeaturedNews {
    newsAndEvents(where: { orderby: { field: DATE, order: DESC } }, first: 4) {
      nodes {
        id
        date
        news {
          title
          featuredImages {
            sourceUrl
            srcSet
          }
          content
        }
      }
    }
  }
`;

export default GET_FEATURED_NEWS;
