import { gql } from "@apollo/client";

const GET_GALLERY = gql`
  query getGallery {
    page(id: "cG9zdDoyMjA=", idType: ID) {
      gallery {
        images {
          sourceUrl
        }
      }
    }
  }
`;

export default GET_GALLERY;
