import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";

const MobileDropdownItem = ({ setShow, index, data, heading }) => {
  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header>{heading}</Accordion.Header>
      <Accordion.Body>
        <div className="link-container">
          {data.map(({ value, link }) => (
            <Link key={link} onClick={() => setShow(false)} to={link}>
              {value}
            </Link>
          ))}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default MobileDropdownItem;
