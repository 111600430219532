import React from "react";
import { Container, Row } from "react-bootstrap";
import NewsCard from "../allNews/NewsCard";
import Query from "../../utils/Query";
import GET_FEATURED_NEWS from "../../queries/news/getFeaturedNews";

const NewsEvents = () => {
  return (
    <Container>
      <div className="home-news">
        <div className="top-section">
          <div className="top-section-header">
            <span className="pill">Our stories</span>
            <h2>Recent News</h2>
          </div>
          <p className="top-section-body">
            Welcome to our news section! Here you will find all the latest
            updates and happenings.
          </p>
        </div>
        <Row>
          <Query query={GET_FEATURED_NEWS}>
            {({
              data: {
                newsAndEvents: { nodes },
              },
            }) => {
              return nodes.map((entry) => (
                <NewsCard key={entry.id} data={entry} />
              ));
            }}
          </Query>
        </Row>
      </div>
    </Container>
  );
};

export default NewsEvents;
