import React from "react";
import { Carousel } from "react-bootstrap";
import GET_SLIDER from "../../queries/slider/getSlider";
import Query from "../../utils/Query";

const Slider = () => {
  return (
    <Query query={GET_SLIDER}>
      {({
        data: {
          page: {
            carousel: { carousel },
          },
        },
      }) => {
        return (
          <Carousel fade pause={false}>
            {carousel.map(({ sourceUrl, srcSet }) => (
              <Carousel.Item key={sourceUrl} interval={3000}>
                <div className="carousel-image-item">
                  <img src={sourceUrl} srcSet={srcSet} alt="" />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        );
      }}
    </Query>
  );
};

export default Slider;
