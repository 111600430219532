import React from "react";
import { Container, Row } from "react-bootstrap";
import NoticeCard from "../components/allNotice/NoticeCard";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import GET_ALL_NOTICE from "../queries/notice/getAllNotice";
import Query from "../utils/Query";

const AllNotice = () => {
  return (
    <div>
      <BreadcumbHeader title="Notices" heading="Notices" />
      <Container className="information">
        <Row>
          <Query query={GET_ALL_NOTICE} sort="DESC">
            {({
              data: {
                notices: { nodes },
              },
            }) => {
              return nodes.map((entry) => (
                <NoticeCard key={entry.id} data={entry} />
              ));
            }}
          </Query>
        </Row>
      </Container>
    </div>
  );
};

export default AllNotice;
