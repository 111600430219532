import { gql } from "@apollo/client";

const GET_DOWNLOADS = gql`
  query getDownloads($sort: OrderEnum!) {
    downloads(first: 1000, where: { orderby: { field: DATE, order: $sort } }) {
      nodes {
        id
        date
        download {
          title
          file {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default GET_DOWNLOADS;
