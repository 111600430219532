import { gql } from "@apollo/client";

const GET_BRANCHES = gql`
  query getBranches {
    branches(first: 1000) {
      nodes {
        branchNetwork {
          branchManager
          branchCodeName
          address
          phoneNumber
          email
          shortDescription
          featuredImage {
            srcSet
            sourceUrl
          }
          members {
            teamName
            membersDetail {
              name
              designation
              image {
                sourceUrl
                srcSet
              }
              email
              phone
            }
          }
          gallery {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_BRANCHES;
