import { gql } from "@apollo/client";

const GET_REPORT_TYPES = gql`
  query getReportTypes {
    reports(first: 1000) {
      nodes {
        slug
        reports {
          title
        }
      }
    }
  }
`;

export default GET_REPORT_TYPES;
