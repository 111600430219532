import React from "react";
import { Container } from "react-bootstrap";
import GET_ABOUT_US from "../../queries/aboutUs/getAboutUS";
import Query from "../../utils/Query";

const AboutUs = () => {
  return (
    <div className="about-us">
      <Query query={GET_ABOUT_US} id="cG9zdDo1Nzg=">
        {({
          data: {
            page: {
              aboutUs: { content, title, featuredImage },
            },
          },
        }) => {
          return (
            <Container>
              <div className="about-us-main">
                <div className="about-us-main-header">
                  <span className="pill">समृद्धिको सहयात्री</span>
                  <h1>{title}</h1>
                </div>
                <div className="about-us-main-body">
                  <img
                    src={featuredImage.sourceUrl}
                    srcSet={featuredImage.srcSet}
                    alt=""
                  />
                  <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </div>
                </div>
              </div>
            </Container>
          );
        }}
      </Query>
    </div>
  );
};

export default AboutUs;
