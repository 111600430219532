import React from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import MemberCard from "../components/message/MemberCard";
import GET_A_TEAM_DETAILS from "../queries/teams/getATeamDetails";
import Query from "../utils/Query";

const Team = () => {
  const slug = useParams().slug;
  return (
    <Query query={GET_A_TEAM_DETAILS} slug={slug}>
      {({ data: { team } }) => {
        return team ? (
          <div>
            <BreadcumbHeader
              title={team.subTeam.title}
              heading={team.subTeam.title}
            />
            <Container className="team-details">
              <Row>
                {team.subTeam.teammembers.map((entry, i) => (
                  <MemberCard data={entry} key={i} />
                ))}
              </Row>
            </Container>
          </div>
        ) : (
          <Container>
            <p className="py-5">Error 404: Not team found</p>
          </Container>
        );
      }}
    </Query>
  );
};

export default Team;
