import React from 'react';
import { Container } from 'react-bootstrap';

const MessageFrom = ({ data }) => {
  return (
    <div className='message'>
      <Container>
        <div className='message-main'>
          {/* <div className='message-main-left'>
            <img
              src={data.featuredImage.sourceUrl}
              srcSet={data.featuredImage.srcSet}
              alt=''
            />
            <div className='body'>
              <span className='designation'>{data.designation}</span>
            </div>
          </div> */}

          <div className='message-main-right'>
            <div className='header'>
              <span className='pill'>समृद्धिको सहयात्री</span>
              <h1>{data.title}</h1>
              {/* <span className='h4 name'>{data.name}</span> */}
            </div>
            <div dangerouslySetInnerHTML={{ __html: data.message }} />
            {/* <div className="body">
              <span className="h4 name">{data.name}</span>
              <span className="designation">{data.designation}</span>
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MessageFrom;
