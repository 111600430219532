import { gql } from "@apollo/client";

const GET_ALL_NEWS = gql`
  query getAllNews($sort: OrderEnum!) {
    newsAndEvents(
      first: 1000
      where: { orderby: { field: DATE, order: $sort } }
    ) {
      nodes {
        id
        date
        news {
          title
          featuredImages {
            sourceUrl
            srcSet
          }
          content
        }
      }
    }
  }
`;

export default GET_ALL_NEWS;
