import { gql } from "@apollo/client";

const GET_A_PRODUCT = gql`
  query getAProduct($slug: ID!) {
    product(id: $slug, idType: SLUG) {
      product {
        title
        description
        featuredimage {
          sourceUrl
          srcSet
        }
        hasSubType
        productSubtype {
          title
          description
          image {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_A_PRODUCT;
