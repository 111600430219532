import React from "react";
import { Col } from "react-bootstrap";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";

const BoardMemberCard = ({ data, level }) => {
  return (
    <Col md={4}>
      <div className={`members-card ${level === 1 ? "mx-md-2" : "mx-md-4"}`}>
        <img
          src={data.image.sourceUrl}
          srcSet={data.image.srcSet}
          alt=""
          style={{ height: `${level === 1 ? "280px" : "250px"}` }}
        />
        <div className="members-card-information">
          <div className="members-card-information-header">
            <h4>{data.name}</h4>
            <h5>{data.designation}</h5>
          </div>
          <div className="members-card-information-bottom">
            {data.email && (
              <div className="icon-container">
                <MdOutlineEmail />
                <span>{data.email}</span>
              </div>
            )}
            {data.phone && (
              <div className="icon-container">
                <MdOutlinePhone />
                <span>{data.phone}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default BoardMemberCard;
