import { gql } from "@apollo/client";

const GET_A_NEWS = gql`
  query getNews($id: ID!) {
    newsAndEvent(id: $id) {
      date
      news {
        title
        featuredImages {
          sourceUrl
          srcSet
        }
        content
      }
    }
  }
`;

export default GET_A_NEWS;
