import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { logobig } from '../../../../../assets';
import NavDropdownItem from './NavDropdownItem';

const LargeNavbar = ({
  aboutUsData,
  productData,
  informationData,
  reportData,
}) => {
  return (
    <div className='my-navbar-main'>
      <Link to='/' className='logo'>
        <img src={logobig} className='logo-image' alt='siddharha coop logo' />
      </Link>
      <ul className='nav'>
        <li>
          <NavLink
            to='/'
            className={({ isActive }) =>
              isActive ? 'active nav-links' : 'nav-links'
            }
          >
            Home
          </NavLink>
        </li>

        <NavDropdownItem heading='About Us' data={aboutUsData} />
        <NavDropdownItem heading='Services' data={productData} />
        <NavDropdownItem heading='Information' data={informationData} />
        <NavDropdownItem heading='Reports' data={reportData} />

        <li>
          <NavLink
            to='/branches'
            className={({ isActive }) =>
              isActive ? 'active nav-links' : 'nav-links'
            }
          >
            Branches
          </NavLink>
        </li>

        <li>
          <NavLink
            to='/gallery'
            className={({ isActive }) =>
              isActive ? 'active nav-links' : 'nav-links'
            }
          >
            Gallery
          </NavLink>
        </li>

        <li>
          <NavLink
            to='/contact-us'
            className={({ isActive }) =>
              isActive ? 'active nav-links' : 'nav-links'
            }
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default LargeNavbar;
