import React from "react";
import {
  MdOutlineEmail,
  MdOutlineLocationOn,
  MdOutlinePhone,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { logobw } from "../../../../assets";

const MainFooter = () => {
  return (
    <div className="footer-main">
      <div className="left-about">
        <div className="footer__item">
          <img src={logobw} alt="" />
          <p className="body">
            सहकारी ऐन २०४८ अनुसार २०५७ सालमा जिल्ला सहकारी कार्यालय नवलपरासीबाट
            दर्ता भएको यस सिद्धार्थ बहुउद्देश्यीय सहकारी संस्था लिमिटेडको मुख्य
            कार्यालय बुटवल ९ मिलनचौकमा रहेको छ भने रुपन्देही जिल्लाको गैंडहवा
            गाउँपालिका वडा न.७ शान्तिबजारमा सेवाकेन्द्र कार्यलय रहेको छ ।
          </p>
        </div>
      </div>
      <div className="right-links">
        <div className="footer__item">
          <h4>About Us</h4>
          <ul>
            <li>
              <Link to="/about">Introduction</Link>
            </li>
            <li>
              <Link to="/management-team">Management Team</Link>
            </li>
            <li>
              <Link to="/board-of-directors">Message From Chairman</Link>
            </li>
          </ul>
        </div>
        <div className="footer__item">
          <h4>Quick Links</h4>
          <ul>
            <li>
              <Link to="/downloads">Downloads</Link>
            </li>
            <li>
              <Link to="/branches">Branches</Link>
            </li>
            <li>
              <Link to="/notice">Notices</Link>
            </li>
          </ul>
        </div>
        <div className="footer__item">
          <h4>Contact Us</h4>
          <ul>
            <li>
              <MdOutlinePhone />
              <span>071-536265</span>
            </li>
            <li>
              <MdOutlineEmail />
              <span>smslbtl@gmail.com</span>
            </li>
            <li>
              <MdOutlineLocationOn />
              <span>Butwal-09, Rupandehi</span>
            </li>
          </ul>
        </div>
        <div className="footer__item">
          <h4>Useful Links</h4>
          <ul>
            <li>
              <a href="http://mof.gov.np/" target="_blank" rel="noreferrer">
                Ministry of Finance
              </a>
            </li>
            <li>
              <a
                href="https://www.nrb.org.np/"
                target="_blank"
                rel="noreferrer"
              >
                Nepal Rastra Bank
              </a>
            </li>
            <li>
              <a
                href="http://www.deoc.gov.np/"
                target="_blank"
                rel="noreferrer"
              >
                Department of Co-operatives
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
