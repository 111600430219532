import React from "react";
import { Container, Row } from "react-bootstrap";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import DownloadCard from "../components/download/DownloadCard";
import GET_DOWNLOADS from "../queries/download/getDownloads";
import Query from "../utils/Query";

const Download = () => {
  return (
    <div>
      <BreadcumbHeader title="Downloads" heading="Downloads" />
      <Container className="information">
        <Row>
          <Query query={GET_DOWNLOADS} sort="DESC">
            {({
              data: {
                downloads: { nodes },
              },
            }) => {
              return nodes.map((entry) => (
                <DownloadCard key={entry.id} data={entry} />
              ));
            }}
          </Query>
        </Row>
      </Container>
    </div>
  );
};

export default Download;
