import React from "react";
import { Container } from "react-bootstrap";
import GET_FEATURED from "../../queries/product/getFeatured";
import Query from "../../utils/Query";
import FeaturedCard from "./FeaturedCard";

const Featured = () => {
  return (
    <div className="home-featured">
      <Container>
        <div className="home-featured-main">
          <div className="left-section">
            <div className="left-section-header">
              <span className="pill">What we provide</span>
              <h2>Our services</h2>
            </div>
            <p className="left-section-body">
              Siddhartha Multipurpose Co-operative is an organization, offering
              a wide range of services to meet the needs of our all individuals.
              Some of the services we offer include:
            </p>
          </div>
          <Query query={GET_FEATURED}>
            {({
              data: {
                products: { nodes },
              },
            }) => {
              return (
                <div className="right-section">
                  {nodes.map((data, index) => (
                    <FeaturedCard
                      data={data.product}
                      id={data.slug}
                      key={index}
                    />
                  ))}
                </div>
              );
            }}
          </Query>
        </div>
      </Container>
    </div>
  );
};

export default Featured;
