import React from "react";
import { Link } from "react-router-dom";
import GET_ALL_PRODUCT from "../../queries/product/getAllProducts";
import Query from "../../utils/Query";

const OtherProducts = ({ slug }) => {
  return (
    <div className="other-products">
      <span>Explore other services</span>
      <Query query={GET_ALL_PRODUCT}>
        {({
          data: {
            products: { nodes },
          },
        }) => {
          return (
            <div className="other-products-list">
              {nodes.map((entry) =>
                entry.slug !== slug ? (
                  <Link key={entry.slug} to={`/product/${entry.slug}`}>
                    {entry.product.title}
                  </Link>
                ) : null
              )}
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default OtherProducts;
