import React from "react";
import Footer from "./footer";
import NavBar from "./nav";
import ScrollToTop from "../../../utils/ScrollToTop";
// import TopNavbar from "./nav/TopNavbar";
import ScrollTopButton from "./ScrollTopButton";

const Layout = ({ children }) => {
  return (
    <div className="page-container">
      <ScrollToTop />
      {/* <TopNavbar /> */}
      <NavBar />
      <main className="page-container-main">{children}</main>
      <Footer />
      <ScrollTopButton />
    </div>
  );
};

export default Layout;
