import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import ContactForm from "../components/contact/ContactForm";
import Map from "../components/contact/Map";

const Contact = () => {
  return (
    <div>
      <BreadcumbHeader title="Contact Us" heading="Contact Us" />
      <Container>
        <div className="contact">
          <Row>
            <Col md={6}>
              <ContactForm />
            </Col>
            <Col md={6}>
              <Map />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
