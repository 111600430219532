import React from "react";
import { Container } from "react-bootstrap";
import MainFooter from "./MainFooter";
import Secondary from "./Secondary";

const Footer = () => {
  return (
    <footer className="footer">
      <Container className="footer-container">
        <MainFooter />
        <Secondary />
      </Container>
    </footer>
  );
};

export default Footer;
