import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/common/layout";
import About from "./containers/About";
import AllNews from "./containers/AllNews";
import AllNotice from "./containers/AllNotice";
import Board from "./containers/Board";
import Branches from "./containers/Branches";
import Contact from "./containers/Contact";
import Download from "./containers/Download";
import Gallery from "./containers/Gallery";
import Home from "./containers/Home";
import Management from "./containers/Management";
import PopUp from "./containers/PopUp";
import Product from "./containers/Product";
import Report from "./containers/Report";
import SingleNews from "./containers/SingleNews";
import Team from "./containers/Team";
import GET_POPUP from "./queries/popUp/getPopup";

const App = () => {
  const { data } = useQuery(GET_POPUP);
  const [showPopup, setShowPopup] = useState(true);

  return (
    <Router>
      {data?.popupInfos?.nodes.length > 0 && showPopup && (
        <PopUp data={data.popupInfos.nodes} setShowPopup={setShowPopup} />
      )}
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<AllNews />} />
          <Route path="/notice" element={<AllNotice />} />
          <Route path="/board-of-directors" element={<Board />} />
          <Route path="/branches" element={<Branches />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/downloads" element={<Download />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/management-team" element={<Management />} />
          <Route path="/product/:slug" element={<Product />} />
          <Route path="/report/:slug" element={<Report />} />
          <Route path="/news/:id" element={<SingleNews />} />
          <Route path="/team/:slug" element={<Team />} />
          <Route path="/*" element={<p>404: Not found</p>} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
