import React from "react";
import { Col } from "react-bootstrap";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";

const ManagementCard = ({ data }) => {
  return (
    <Col md={3}>
      <div className="members-card">
        {/* <div className="members-card-image">
          <img
            src={data.image.sourceUrl}
            srcSet={data.image.srcSet}
            alt={data.name}
          />
        </div> */}
        <div className="members-card-information management-card">
          <div className="members-card-information-header">
            <h5>{data.designation}</h5>
            <h4>{data.name}</h4>
          </div>
          <div className="members-card-information-bottom">
            {data.email && (
              <div className="icon-container">
                <MdOutlineEmail />
                <span>{data.email}</span>
              </div>
            )}
            {data.phone && (
              <div className="icon-container">
                <MdOutlinePhone />
                <span>{data.phone}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ManagementCard;
