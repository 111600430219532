import React from "react";
import { Container, Row } from "react-bootstrap";
import BoardMemberCard from "../components/message/BoardMemberCard";
import MemberCard from "../components/message/MemberCard";
import MessageFrom from "../components/message/MessageFrom";
import GET_BOARD_MEMBERS from "../queries/board/getAllBoardMemebers";
import GET_MESSAGE_FROM from "../queries/messageFrom/getMessageFrom";
import Query from "../utils/Query";

const Board = () => {
  return (
    <div>
      <Query query={GET_MESSAGE_FROM} id="cG9zdDo1ODA=">
        {({
          data: {
            page: { messageFrom: data },
          },
        }) => {
          return <MessageFrom data={data} />;
        }}
      </Query>
      <Container className="members">
        <h2>Board of directors</h2>
        <Query query={GET_BOARD_MEMBERS}>
          {({
            data: {
              boardOfDirectors: { nodes },
            },
          }) => {
            return (
              <>
                <Row className="justify-content-center mb-md-4">
                  <BoardMemberCard data={nodes[0].boardOfDirector} level={1} />
                </Row>
                <Row className="justify-content-between mb-md-5">
                  {nodes.slice(1, 3).map(({ boardOfDirector }, key) => (
                    <BoardMemberCard
                      key={key}
                      data={boardOfDirector}
                      level={2}
                    />
                  ))}
                </Row>
                <Row className="justify-content-center">
                  {nodes.slice(3).map(({ boardOfDirector }, key) => (
                    <MemberCard key={key} data={boardOfDirector} />
                  ))}
                </Row>
              </>
            );
          }}
        </Query>
        {/* </div> */}
      </Container>
    </div>
  );
};

export default Board;
