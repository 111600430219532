import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

const FeaturedCard = ({ data, id }) => {
  return (
    <Link to={`/product/${id}`} className="featured-card">
      <div className="featured-card-image">
        <img src={data.icon.sourceUrl} srcSet={data.icon.srcSet} alt="" />
      </div>
      <span className="featured-card-header">{data.title}</span>
      <p className="featured-card-body">{data.homepageDescription}</p>
      <div className="featured-card-link">
        <span>Learn More</span>
        <AiOutlineArrowRight />
      </div>
    </Link>
  );
};

export default FeaturedCard;
