import React from "react";

const ProductItem = ({ data, index }) => {
  return (
    <li className="product-children-list-item">
      <div className="product-children-list-item-body">
        {data.image && (
          <div className="product-image-container">
            <img
              src={data.image.sourceUrl}
              srcSet={data.image.srcSet}
              alt={data.title}
            />
          </div>
        )}
        <h4>{data.title}</h4>
        {data.description && (
          <p dangerouslySetInnerHTML={{ __html: data.description }} />
        )}
      </div>
    </li>
  );
};

export default ProductItem;
