import React from "react";

const AboutSection = ({ data }) => {
  return (
    <li className="mission-vision-goal-item">
      <div className="content">
        <h2>{data.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </div>
      <div className="image">
        <img
          src={data.featuredImage.sourceUrl}
          srcSet={data.featuredImage.srcSet}
          alt=""
        />
      </div>
    </li>
  );
};

export default AboutSection;
