import React from "react";
import { MdArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";
import GET_RECENT_NEWS from "../../queries/news/getRecentNews";
import Query from "../../utils/Query";

const LatestNews = ({ newsId }) => {
  return (
    <div className="news-latest">
      <h3>Latest News</h3>
      <ul>
        <Query query={GET_RECENT_NEWS} notin={newsId}>
          {({
            data: {
              newsAndEvents: { nodes: data },
            },
          }) => {
            return data.map(({ id, news }) => (
              <li key={id}>
                <Link to={`/news/${id}`}>
                  <MdArrowForward />
                  <span>{news.title}</span>
                </Link>
              </li>
            ));
          }}
        </Query>
      </ul>
    </div>
  );
};

export default LatestNews;
