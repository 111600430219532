import React from "react";

const Map = () => {
  return (
    <div className="contact-map">
      {/* <h2>Our location</h2> */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.897097934071!2d83.46168636443402!3d27.68957513288096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39968662af7e7e57%3A0x5a6ebfecb8ba4a16!2sMilan%20Chowk%2C%20Butwal%2032907%2C%20Nepal!5e0!3m2!1sen!2sin!4v1675757767719!5m2!1sen!2sin"
        title="map"
      ></iframe>
    </div>
  );
};

export default Map;
