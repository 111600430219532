import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const BreadcumbHeader = ({ title, heading }) => {
  return (
    <div className="breadcumb">
      <Container className="breadcumb-container">
        <div className="breadcumb-container-header">
          <h1>{heading}</h1>
        </div>
        <div className="breadcumb-container-breadcumb">
          <Link to="/" className="home-link">
            Home
          </Link>
          <FaAngleRight />
          <span className="page-name">{title}</span>
        </div>
      </Container>
    </div>
  );
};

export default BreadcumbHeader;
