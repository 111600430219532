import React from "react";
import { Col } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import extractTextOnly from "../../utils/extractTextOnly";

const NewsCard = ({ data }) => {
  var description = extractTextOnly(data.news.content).substring(0, 50);
  description = description + "...";
  return (
    <Col md={3}>
      <Link to={`/news/${data.id}`} className="news-card">
        <div className="news-card-image">
          <img
            src={data.news.featuredImages.sourceUrl}
            srcSet={data.news.featuredImages.srcSet}
            alt=""
          />
        </div>
        <div className="news-card-body">
          <div className="news-card-body-header">
            <Moment className="date" format="DD MMM YYYY">
              {data.date}
            </Moment>
            <span className="title">{data.news.title}</span>
          </div>
          <p
            className="news-card-body-content"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="news-card-body-link">
            <span>Learn More</span>
            <AiOutlineArrowRight />
          </div>
        </div>
      </Link>
    </Col>
  );
};

export default NewsCard;
