import { gql } from "@apollo/client";

const GET_BOARD_MEMBERS = gql`
  query getBoardMembers {
    boardOfDirectors(first: 1000) {
      nodes {
        boardOfDirector {
          name
          designation
          email
          phone
          image {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_BOARD_MEMBERS;
