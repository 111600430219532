import React from "react";
// import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const Secondary = () => {
  return (
    <div className="footer-secondary">
      <span className="copy-info">
        Copyright &copy; {new Date().getFullYear()} Siddhartha Multipurpose
        Co-operative Limited. All Rights Reserved
      </span>
      {/* <ul className="socials">
        <li>
          <a
            className="link"
            href="http://www.facebook.com/"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebook />
          </a>
        </li>
        <li>
          <a
            className="link"
            href="http://www.youtube.com/"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube />
          </a>
        </li>
        <li>
          <a
            className="link"
            href="http://www.instagram.com/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
      </ul> */}
    </div>
  );
};

export default Secondary;
