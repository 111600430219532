import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ApolloProvider } from "@apollo/client";
import client from "./utils/ApolloClient";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
  // </React.StrictMode>
);
