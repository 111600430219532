import { gql } from "@apollo/client";

const GET_ALL_TEAMS = gql`
  query getAllTeams {
    teams(first: 1000) {
      nodes {
        slug
        subTeam {
          title
        }
      }
    }
  }
`;

export default GET_ALL_TEAMS;
