import React from "react";
import Featured from "../components/home/Featured";
import Introduction from "../components/home/Introduction";
import NewsEvents from "../components/home/NewsEvents";
import Slider from "../components/home/Slider";

const Home = () => {
  return (
    <div>
      <Slider />
      <Introduction />
      <Featured />
      <NewsEvents />
    </div>
  );
};

export default Home;
