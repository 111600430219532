import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import LatestNews from "../components/singleNews/LatestNews";
import GET_A_NEWS from "../queries/news/getANews";
import Query from "../utils/Query";

const SingleNews = () => {
  const id = useParams().id;
  return (
    <Query query={GET_A_NEWS} id={id}>
      {({
        data: {
          newsAndEvent: { date, news },
        },
      }) => {
        return (
          <Container>
            <div className="news">
              <div className="news-header">
                <h1 className="title">{news.title}</h1>
                <Moment format="DD MMM YYYY" className="date">
                  {date}
                </Moment>
              </div>
              <Row>
                <Col md={8}>
                  <div className="news-body">
                    <img
                      src={news.featuredImages.sourceUrl}
                      srcSet={news.featuredImages.srcSet}
                      alt=""
                    />
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: news.content }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <LatestNews newsId={id} />
                </Col>
              </Row>
            </div>
          </Container>
        );
      }}
    </Query>
  );
};

export default SingleNews;
