import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const captchaRef = useRef();

  const [submitStatus, setSubmitStatus] = useState("idle");

  const [captcha, setCaptcha] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [messageError, setMessageError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitStatus("loading");

    let err = false;
    // console.log(form.current);

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobileRegex = /^9[78]\d{8}$/;

    if (!name || !name.trim()) {
      setNameError("Please provide name");
      err = true;
    }
    if (!email || !email.trim()) {
      setEmailError("Please provide an email");
      err = true;
    } else if (!emailRegex.test(email.toLowerCase())) {
      setEmailError("Please enter a valid email");
      err = true;
    }
    if (!message || !message.trim()) {
      setMessageError("Please provide a message");
      err = true;
    }
    if (!mobile || !mobile.trim()) {
      setMobileError("Please provide mobile number");
      err = true;
    } else if (!mobileRegex.test(mobile)) {
      setMobileError("Please provide a valid mobile number");
      err = true;
    }
    if (!err) {
      const data = { name, email, mobile, message };
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          data,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setSubmitStatus("success");
            captchaRef.current.reset();
            setCaptcha(false);
            clearForm();
            setTimeout(() => {
              setSubmitStatus("idle");
            }, 3000);
          },
          (error) => {
            console.log(error.text);
            setSubmitStatus("idle");
          }
        );
    } else {
      setSubmitStatus("idle");
    }
  };

  const clearForm = () => {
    setName("");
    setNameError("");
    setEmail("");
    setEmailError("");
    setMessage("");
    setMessageError("");
    setMobile("");
    setMobileError("");
  };

  const onCaptchaChange = (e) => {
    if (e) {
      setCaptcha(true);
    } else {
      setCaptcha(false);
    }
  };

  return (
    <div className="contact-form">
      <h2>Have a message?</h2>
      <div className="header-message">
        <small>
          If you have any query or suggestions. Please feel free to leave a
          messsage.
        </small>
      </div>
      <form onSubmit={handleSubmit} className="contact-form-main">
        <div className="contact-form-main-row">
          <div className="contact-form-main-item">
            <label htmlFor="name">Full Name</label>
            <input
              id="name"
              type="text"
              value={name}
              placeholder="Enter your name"
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
            />
            {nameError && <span>{nameError}</span>}
          </div>
          <div className="contact-form-main-item">
            <label htmlFor="mobile">Mobile Number</label>
            <input
              id="mobile"
              type="text"
              value={mobile}
              placeholder="Enter your mobile number"
              onChange={(e) => {
                setMobile(e.target.value);
                setMobileError("");
              }}
            />
            {mobileError && <span>{mobileError}</span>}
          </div>
        </div>
        <div className="contact-form-main-item">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="text"
            value={email}
            placeholder="Enter your email"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
          />
          {emailError && <span>{emailError}</span>}
        </div>
        <div className="contact-form-main-item">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            type="text"
            rows={5}
            value={message}
            placeholder="Enter your message"
            onChange={(e) => {
              setMessage(e.target.value);
              setMessageError("");
            }}
          />
          {messageError && <span>{messageError}</span>}
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          onChange={onCaptchaChange}
          ref={captchaRef}
        />
        <button type="submit" disabled={!captcha || submitStatus === "loading"}>
          {submitStatus === "loading"
            ? "Loading..."
            : submitStatus === "success"
            ? "Thankyou"
            : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
