// import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
// import PopUpItem from "../components/popUp/PopUpItem";

const PopUp = ({ data, setShowPopup }) => {
  // const [show, setShow] = useState(false);
  // const [title, setTitle] = useState("");
  // const [url, setUrl] = useState("");
  // const [index, setIndex] = useState(0);

  // useEffect(() => {
  //   if (!show) {
  //     if (index < data.length) {
  //       const temp = data[index];
  //       setTitle(temp.title);
  //       setUrl(temp.popupInfo.image.mediaItemUrl);
  //       setShow(true);
  //       setIndex((prev) => prev + 1);
  //     } else {
  //       setShowPopup(false);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [show]);

  return (
    <div className="popup">
      {/* {show && <PopUpItem title={title} url={url} setShow={setShow} />} */}
      <div className="popup-container">
        <div className="popup-container-item">
          <div className="popup-container-item-header">
            {/* <h3>{title}</h3> */}
            <button onClick={() => setShowPopup(false)}>
              <FaTimes />
            </button>
          </div>
          <div className="popup-container-item-image">
            <Carousel variant="dark">
              {data.map(({ title, popupInfo }, index) => (
                <Carousel.Item key={index} interval={10000}>
                  <span>
                    Notice {index + 1} of {data.length}
                  </span>
                  <h3>{title}</h3>
                  <img
                    fetchpriority="high"
                    src={popupInfo.image.mediaItemUrl}
                    srcSet={popupInfo.image.srcSet}
                    alt=""
                    placeholder="fade"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
