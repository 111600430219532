import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ModalImage from "react-modal-image";
import Query from "../utils/Query";
import GET_GALLERY from "../queries/gallery/getGallery";
import BreadcumbHeader from "../components/common/BreadcumbHeader";

const Gallery = () => {
  return (
    <div>
      <BreadcumbHeader title="Gallery" heading="Gallery" />
      <Container>
        <div className="gallery">
          <Row>
            <Query query={GET_GALLERY}>
              {({
                data: {
                  page: {
                    gallery: { images },
                  },
                },
              }) => {
                return images.map(({ sourceUrl }, key) => (
                  <Col md={4} key={key}>
                    <ModalImage
                      small={sourceUrl}
                      large={sourceUrl}
                      // alt="photo"
                      hideDownload={true}
                      hideZoom={true}
                      className="image-container"
                      imageBackgroundColor="transparent"
                    />
                  </Col>
                ));
              }}
            </Query>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Gallery;
