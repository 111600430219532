import React, { useState } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { logo, logobig } from "../../../../../assets";
import MobileDropdownItem from "./MobileDropdownItem";

const MobileNavbar = ({
  aboutUsData,
  productData,
  informationData,
  reportData,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="mobile-nav">
        <Link to="/">
          <img src={logobig} className="" alt="" />
        </Link>
        <button
          className="show-nav"
          type="button"
          onClick={() => setShow(true)}
        >
          <HiOutlineBars3 />
        </button>
      </div>
      <Offcanvas
        show={show}
        onHide={() => setShow(false)}
        responsive="xl"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <img src={logo} className="" alt="" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mobile-sidebar">
            {/* <div className="mobile-sidebar-link"> */}
            <Link
              className="mobile-sidebar-link"
              onClick={() => setShow(false)}
              to="/"
            >
              Home
            </Link>
            {/* </div> */}
            <Accordion flush>
              <MobileDropdownItem
                index="0"
                data={aboutUsData}
                heading="About Us"
                setShow={setShow}
              />
              <MobileDropdownItem
                index="1"
                data={productData}
                heading="Services"
                setShow={setShow}
              />
              <MobileDropdownItem
                index="2"
                data={informationData}
                heading="Information"
                setShow={setShow}
              />
              <MobileDropdownItem
                index="3"
                data={reportData}
                heading="Reports"
                setShow={setShow}
              />
            </Accordion>

            <Link
              className="mobile-sidebar-link top-border"
              onClick={() => setShow(false)}
              to="/branches"
            >
              Branches
            </Link>

            <Link
              className="mobile-sidebar-link"
              onClick={() => setShow(false)}
              to="/gallery"
            >
              Gallery
            </Link>

            <Link
              className="mobile-sidebar-link"
              onClick={() => setShow(false)}
              to="/contact-us"
            >
              Contact Us
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default MobileNavbar;
