import React from "react";
import { Container } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import GET_ABOUT_US from "../../queries/aboutUs/getAboutUS";
import extractTextOnly from "../../utils/extractTextOnly";
import Query from "../../utils/Query";

const Introduction = () => {
  return (
    <Container>
      <Query query={GET_ABOUT_US} id="cG9zdDo1Nzg=">
        {({
          data: {
            page: { aboutUs },
          },
        }) => {
          var description = extractTextOnly(aboutUs.content);
          if (description.length > 800) {
            description = description.substring(0, 800) + "...";
          }
          return (
            <div className="home-introduction">
              <div className="left-image">
                <img
                  src={aboutUs.featuredImage.sourceUrl}
                  srcSet={aboutUs.featuredImage.srcSet}
                  alt=""
                  className=""
                />
              </div>
              <div className="right-section">
                <div className="right-section-header">
                  <div className="pill">
                    <span>Our Story</span>
                  </div>
                  <h2>{aboutUs.title}</h2>
                </div>
                <p
                  className="right-section-body"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <Link to="/about" className="right-section-learn-more">
                  <span>Learn More</span>
                  <AiOutlineArrowRight />
                </Link>
              </div>
            </div>
          );
        }}
      </Query>
    </Container>
  );
};

export default Introduction;
