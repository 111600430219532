import { gql } from "@apollo/client";

const GET_FEATURED = gql`
  query getFeatured {
    products(first: 2) {
      nodes {
        slug
        product {
          title
          homepageDescription
          icon {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_FEATURED;
