import React from "react";
import { Col } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import Moment from "react-moment";

const NoticeCard = ({ data }) => {
  return (
    <Col md={3}>
      <a
        href={data.notices.notice.mediaItemUrl}
        download
        target="_blank"
        rel="noreferrer"
        className="news-card"
      >
        <div className="news-card-image">
          <img
            src={data.notices.featuredImage.sourceUrl}
            srcSet={data.notices.featuredImage.srcSet}
            alt=""
          />
        </div>
        <div className="news-card-body">
          <div className="news-card-body-header">
            <Moment className="date" format="DD MMM YYYY">
              {data.date}
            </Moment>
            <span className="title">{data.notices.title}</span>
          </div>
          <div className="news-card-body-link">
            <span>Learn More</span>
            <AiOutlineArrowRight />
          </div>
        </div>
      </a>
    </Col>
  );
};

export default NoticeCard;
