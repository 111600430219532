import { gql } from "@apollo/client";

const GET_ALL_PRODUCT = gql`
  query getAllProducts {
    products(first: 1000) {
      nodes {
        slug
        product {
          title
        }
      }
    }
  }
`;

export default GET_ALL_PRODUCT;
