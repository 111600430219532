import React from "react";
import { Container } from "react-bootstrap";
import AboutSection from "../components/about/AboutSection";
import AboutUs from "../components/about/AboutUs";
import GET_MISSION_VISION_GOAL from "../queries/missionVisionGoal/getMissionVisionGoal";
import Query from "../utils/Query";

const About = () => {
  return (
    <div>
      <AboutUs />
      <Container>
        <Query query={GET_MISSION_VISION_GOAL} id="cG9zdDo1NzQ=">
          {({
            data: {
              page: {
                missionVisionGoal: { variousInfo },
              },
            },
          }) => {
            return (
              <ul className="mission-vision-goal">
                {variousInfo.map((entry, key) => (
                  <AboutSection data={entry} key={key} />
                ))}
              </ul>
            );
          }}
        </Query>
      </Container>
    </div>
  );
};

export default About;
