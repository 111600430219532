import { gql } from "@apollo/client";

const GET_MANAGEMENT_MEMBERS = gql`
  query getManagementMembers {
    managementTeams(first: 1000) {
      nodes {
        managementTeam {
          designation
          email
          name
          phone
        }
      }
    }
  }
`;

export default GET_MANAGEMENT_MEMBERS;
