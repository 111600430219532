import { gql } from "@apollo/client";

const GET_REPORTS = gql`
  query getReports($slug: ID!) {
    report(id: $slug, idType: SLUG) {
      reports {
        title
        downloadFiles {
          dateCustom
          title
          reportFile {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default GET_REPORTS;
