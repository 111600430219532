import React from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import ReportCard from "../components/report/ReportCard";
import GET_REPORTS from "../queries/report/getReports";
import Query from "../utils/Query";

const Report = () => {
  const slug = useParams().slug;

  return (
    <Query query={GET_REPORTS} slug={slug}>
      {({ data: { report } }) => {
        return report ? (
          <div>
            <BreadcumbHeader
              title={report.reports.title}
              heading={report.reports.title}
            />
            <Container className="information">
              <Row>
                {report.reports.downloadFiles.map((entry, key) => (
                  <ReportCard key={key} data={entry} />
                ))}
              </Row>
            </Container>
          </div>
        ) : (
          <Container>
            <p className="py-5">Error 404: Report Type not found</p>
          </Container>
        );
      }}
    </Query>
  );
};

export default Report;
