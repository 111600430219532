import React from "react";
import { Container, Row } from "react-bootstrap";
import NewsCard from "../components/allNews/NewsCard";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import GET_ALL_NEWS from "../queries/news/getAllNews";
import Query from "../utils/Query";

const AllNews = () => {
  return (
    <div>
      <BreadcumbHeader title="News and Events" heading="News" />
      <Container className="information">
        <Row>
          <Query query={GET_ALL_NEWS} sort="DESC">
            {({
              data: {
                newsAndEvents: { nodes },
              },
            }) => {
              return nodes.map((entry) => (
                <NewsCard key={entry.id} data={entry} />
              ));
            }}
          </Query>
        </Row>
      </Container>
    </div>
  );
};

export default AllNews;
