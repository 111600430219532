import { gql } from "@apollo/client";

const GET_A_TEAM_DETAILS = gql`
  query getATeamDetails($slug: ID!) {
    team(id: $slug, idType: SLUG) {
      subTeam {
        title
        teammembers {
          name
          designation
          phone
          email
          image {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

export default GET_A_TEAM_DETAILS;
