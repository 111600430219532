import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  MdOutlineEmail,
  MdOutlineLocationOn,
  MdOutlinePhone,
  MdPersonOutline,
} from "react-icons/md";
import MemberCard from "../message/MemberCard";
import ModalImage from "react-modal-image";

const BranchDetail = ({ data }) => {
  return (
    <div className="branch">
      <div className="branch-header">
        <h2>{data.branchCodeName}</h2>
      </div>
      <div className="entry-container">
        {data.branchManager && (
          <div className="entry">
            <MdPersonOutline />
            <span>{data.branchManager}</span>
          </div>
        )}
        {data.address && (
          <div className="entry">
            <MdOutlineLocationOn />
            <span>{data.address}</span>
          </div>
        )}
        {data.phoneNumber && (
          <div className="entry">
            <MdOutlinePhone />
            <span>{data.phoneNumber}</span>
          </div>
        )}
        {data.email && (
          <div className="entry">
            <MdOutlineEmail />
            <span>{data.email}</span>
          </div>
        )}
      </div>
      <div className="branch-body">
        <div className="branch-body-content">
          <h3>About the branch</h3>
          <Row className="branch-body-content-about">
            <Col md={7} className="branch-body-content-about-body">
              <div
                dangerouslySetInnerHTML={{ __html: data.shortDescription }}
              />
            </Col>
            <Col md={5} className="branch-body-content-image">
              <img
                src={data.featuredImage.sourceUrl}
                srcSet={data.featuredImage.srcSet}
                alt={data.branchCodeName}
              />
            </Col>
          </Row>
        </div>
        {data.members &&
          data.members.map(({ teamName, membersDetail }, key) => (
            <div key={key} className="branch-body-team">
              <h3 className="branch-body-team-header">{teamName}</h3>
              <Row>
                {membersDetail.map((entry, key2) => (
                  <MemberCard data={entry} key={key2} />
                ))}
              </Row>
            </div>
          ))}
        {data.gallery && (
          <div className="branch-body-gallery">
            <h3>Gallery</h3>
            <Row>
              {data.gallery.map(({ sourceUrl }, key) => (
                <Col md={4} key={key}>
                  <ModalImage
                    small={sourceUrl}
                    large={sourceUrl}
                    // alt="photo"
                    hideDownload={true}
                    hideZoom={true}
                    className="image-container"
                    imageBackgroundColor="transparent"
                  />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default BranchDetail;
