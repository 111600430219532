import { gql } from "@apollo/client";

const GET_RECENT_NEWS = gql`
  query getFeaturedNews($notin: [ID]) {
    newsAndEvents(
      where: { orderby: { field: DATE, order: DESC }, notIn: $notin }
      first: 5
    ) {
      nodes {
        id
        news {
          title
        }
      }
    }
  }
`;

export default GET_RECENT_NEWS;
